.loginPage{
    background-color: #d8eef8;
    background: url(../../assets/img/Green_planet-02.jpg);
    height: 100vh;
    background-size: 100% 85%;
    background-repeat: no-repeat;
}

.loginPage .container, .loginPage .row{
    height: 100%;
}

.loginPresentation{
    
    background: linear-gradient(90deg, rgb(41, 38, 90) 0%,  rgb(248,249,254) 100%);
}

.loginPage .form-control{
    border: none;
    border-radius: 0;
    border-bottom-color: #5E72E4;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    background: rgba(0, 0, 0, 0)
}

.loginPage .form-control:focus{
    box-shadow: none;
    background: rgba(0, 0, 0, 0);
    border-bottom-width: 2px;
    border-bottom-color: #5E72E4;
}
.logo-img{
    width: 150px!important;
    max-height: 80px!important;
    height: 80px!important;
}